import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import {  MdOutlineClose, MdOutlineShare, } from "react-icons/md";
import SwiperComponent from "./swiperComponent";
const IssueItem = () => {
  const [page, setPage] = useState();
  const [pages, setPages] = useState([]);
  const [pageId, setPageId] = useState();
  const handleNextPrev = (id) => {
    if(id !== "") {setPageId(id)}else {};
  }
  const location = useLocation();
  const navigate = useNavigate();
  const firstPage = location.state.firstPage;
  useEffect(()=> {setPageId(firstPage)},[firstPage])
  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({id: pageId})
  };
  fetch(`https://api.jaygasht.ir/page/?query=${pageId}`, requestOptions)
      .then(response => response.json())
      .then(data => {setPage(data); pages.push(data);})
 }, [pageId]);
  return (
    <div className=" bg-blackA backdrop-blur-[2px] rounded-lg bg-opacity-95 w-screen  2xl:max-w-[2000px] ">
     {page && <div>
      <div className=" flex animate-fade-down sticky top-0 z-10 items-center justify-between py-[20px] pr-[20px] pl-[93px] 2xl:py-[40px] 2xl:text-[24px] 2xl:pr-[72px] 2xl:pl-[556px] 2xl:h-[100px] 2xl:bg-blackB ">
        <div className="flex items-center gap-4 justify-between text-cream ml-[24px] 2xl:ml-[370px] ">
          <div className=" text-[20px] 2xl:text-[32px] "><div className="text-cream cursor-pointer hover:scale-110" onClick={()=> navigate(-1)}><MdOutlineClose/></div></div>

          <div className="2xl:text-[32px] 2xl:cursor-pointer"><MdOutlineShare/></div>
        </div>
        <div className="flex flex-grow max-w-[200px] 2xl:max-w-[328px] items-center justify-between text-whiteD">
          <div className="text-cream font-semibold">{page.magazine_title}</div>
          <div> سال {page.issue_year}</div>
          <div>شماره {page.issue_number} </div>
        </div>
        <div></div>
      </div>
      <SwiperComponent pages={pages} handleNextPrev={handleNextPrev} />
      {/* <div className="h-[56px] text-base 2xl:text-xl text-cream flex justify-center items-center font-bold ">
      {page.page_number} از ...
      </div> */}
      </div>}
   
    
    </div>
  );
};

export default IssueItem;