import React from 'react';
import share from "../../common/pic/share.svg";
import castBox from "../../common/pic/castBox.svg";
import spotify from "../../common/pic/spotify.svg";
import apple from "../../common/pic/Apple.svg";
import googlePodcast from "../../common/pic/googlePodcast.svg";
import Info from "../../common/pic/info.svg"
import { MdInfo, MdPause, MdPlayArrow,  } from 'react-icons/md';

const AudioItem = ({ clickAudioItem, id, icon, handleInfo, post}) => {
  let str = post.title;
  let newStr = str.substring(0, 36);
  newStr += '...';
    return (
        <div className="flex justify-center relative  animate-fade-left">
          <div className="grid grid-cols-5 items-center pr-4 2xl:pr-0 pl-[22px] w-[320px] min-h-[88px] rounded-lg bg-blackA  2xl:min-w-[240px]  2xl:justify-center 2xl:bg-transparent 2xl:block 2xl:pl-[0px] ">
            <div className=" col-span-1">
              <div
                className="relative w-14 h-14 rounded-lg 2xl:w-60 2xl:h-60"
              >
              <img
                  src={post.cover}
                  className=" w-full h-full rounded-lg"
                  alt={post.title}
                />
                <div onClick={() => {clickAudioItem(id, 0);}} className="w-[18px] h-[18px] rounded-[50%]  absolute top-[19px] left-[19px] cursor-pointer 2xl:w-10 2xl:h-10 2xl:top-[100px] 2xl:left-[100px]">
                  <div
                    className={`btn-container-home rounded-[50%] hover:scale-105 ${
                      icon
                        ? "bg-[#2B2B2B]"
                        : "bg-[#E7DB9D]"
                    }`}
                  >
                    <div>
                      
                      {!icon ? <MdPlayArrow className='w-3 h-3 2xl:w-7 2xl:h-7 cursor-pointer  fill-blackA '/>:
                      <MdPause className='w-3 h-3 2xl:w-7 2xl:h-7 cursor-pointer fill-cream animate-pulse'/>}
                    </div>
                  </div>
                </div>
                {/* details of ep */}
                <div onClick={()=>handleInfo(post.description, post.channel, post.title)} className='absolute  hidden rounded-full w-6 h-6 2xl:block 2xl:top-5 2xl:right-5 '>
                  <MdInfo className=' cursor-pointer text-cream w-[30px] h-[30px] hover:scale-105'/>       
                </div>
                {/* details of ep */}
              </div>
            </div>
            <div className=" col-span-4 mr-4  w-[218px] 2xl:min-w-60 2xl:mr-0 2xl:mt-5">
              <div className=" hidden text-cream 2xl:flex items-center 2xl:text-base 2xl:leading-8 2xl:h-full 2xl:font-semibold">
                {post.title}
              </div>
              <div className=" h-[13px] text-xs overflow-hidden leading-[19px] text-cream flex items-center 2xl:hidden">
                {(str.length>36)?newStr: str}
              </div>
              <div className=" h-[13px] my-2 text-[11px] leading-[17px] text-time flex items-center 2xl:h-full 2xl:text-base 2xl:leading-7 2xl:font-normal 2xl:my-4">
                {post.channel}
              </div>
              <div className={`flex items-center h-[14px] 2xl:absolute 2xl:h-[56px] 2xl:min-w-[240px] 2xl:backdrop-blur-[2px] 2xl:rounded-lg 2xl:bg-blackA 2xl:opacity-0 2xl:hover:opacity-80 2xl:hover:animate-fade-up 2xl:top-[184px] `}>
                <div className="flex items-center 2xl:px-6 2xl:gap-4 2xl:justify-between">
                  <a href={post.gpod} target={"_blank"} rel={"noreferrer"}>
                    <img
                      src={googlePodcast}
                      alt=""
                      className="w-[14px] h-full 2xl:w-6 text-cream hover:scale-105"
                    />
                  </a>
                  <a
                    className='mr-4 2xl:mr-0'
                    href={post.spotify}
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    <img
                      src={spotify}
                      alt=""
                      className="w-[14px] h-full 2xl:w-6 text-cream hover:scale-105"
                    />
                  </a>
                  <a className='mr-4 2xl:mr-0' href={post.castbox} target={"_blank"} rel={"noreferrer"}>
                    <img
                      src={castBox}
                      alt=""
                      className="w-[13px] h-full 2xl:w-6 text-cream hover:scale-105"
                    />
                  </a>
                  <a className='mr-4 2xl:mr-0' href={post.apod} target={"_blank"} rel={"noreferrer"}>
                    <img
                      src={apple}
                      alt=""
                      className="w-[13px] h-full 2xl:w-6 text-cream hover:scale-105"
                    />
                  </a>
                  <div className='mr-4 2xl:mr-0'  >
                    <img
                      src={share}
                      alt=""
                      className="w-[13px] h-full 2xl:w-6 text-cream hover:scale-105"
                    />
                  </div>
                </div>
                <div className="w-[83px] text-[11px] leading-[17px] flex items-center justify-between text-time 2xl:hidden">
                <div onClick={() => handleInfo(post.description, post.channel, post.title)}>
                  <img
                      src={Info}
                      alt=""
                      className="w-[11px] mr-4 h-[11px] 2xl:w-6 cursor-pointer text-cream opacity-80 hover:scale-105"
                    />
                  </div>
                  <div className=" text-[11px] w-7 h-2"></div>
                  50:48
                </div>
              </div> 
            </div>
          </div>
        </div>
    );
}

export default AudioItem;
