import React, { useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

// const DropDown = ({details}) => {
//     const [option, setOption] = useState();
//     const [dropDownContext,setDropDownContext] = useState(false)
//     const handleDropDown = () => {
//         !dropDownContext? setDropDownContext(true) : setDropDownContext(false)
//     }
//     const handleClick = (e) =>{
//         setOption(e.target.title)
//         !dropDownContext? setDropDownContext(true) : setDropDownContext(false)
//         console.log(option)
//     }
//     return (
//         <div className='relative'>
//             <div onClick={()=>handleDropDown()} className=" relative flex items-center border-[1px] border-cream rounded-lg shadow-[0px_4px_4px_rgba(0,0,0,0.25)] h-8 2xl:h-12">
//           <p className="absolute -top-3 text-sm 2xl:text-base bg-blackB text-whiteC right-3 text-center h-4 px-[6px]">
//             {details.title}
//           </p>
//           <MdKeyboardArrowDown className="cursor-pointer absolute left-2 w-5 h-5 2xl:w-7 2xl:h-7 text-whiteC  " />
//           <h3 className=' text-cream text-[12px] md:text-sm pt-2 pr-2 w-full '>{option}</h3>
//         </div>
//         <div className={` absolute bg-blackB w-full border-[1px] border-cream rounded-lg shadow-[0px_4px_4px_rgba(0,0,0,0.25)] py-2 md:py-6 transition-all duration-75 ease-linear ${!dropDownContext? 'invisible -z-50': ' z-50 visible'} `}>
// 
//             {details.options.map((opt)=>{ return <div className=' hover:bg-blackA hover:text-cream text-sm leading-6 w-full px-2 md:px-6' key={Object.keys(opt)[0]} title={Object.values(opt)[0]} id={Object.keys(opt)[0]} onClick={(e)=>handleClick(e)}>{Object.values(opt)[0]}</div>})}
//         </div>
//         </div>
//     );
// }
// 
// export default DropDown;

const DropDown = ({ details, onFilterSelect }) => {
    const [option, setOption] = useState(null);
    const [dropDownContext, setDropDownContext] = useState(false);
  
    const handleDropDown = () => {
      setDropDownContext(!dropDownContext);
    };
  
    const handleClick = (optionId, label) => {
      if (!details.disable) {
      setOption(label);
      setDropDownContext(false);
      onFilterSelect(details.id, optionId);}
    };
  
    return (
      <div className="relative">
        <div
          onClick={handleDropDown}
          className={`relative flex items-center border-[1px] border-cream rounded-lg shadow-[0px_4px_4px_rgba(0,0,0,0.25)] h-8 2xl:h-12 ${details.disable?"opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
        >
          <p className="absolute -top-3 text-sm 2xl:text-base bg-blackB text-whiteC right-3 text-center h-4 px-[6px]">
            {details.title}
          </p>
          <MdKeyboardArrowDown className="cursor-pointer absolute left-2 w-5 h-5 2xl:w-7 2xl:h-7 text-whiteC" />
          <h2 className="text-cream text-[12px] md:text-sm pt-2 pr-2 w-full">
            {option}
          </h2>
        </div>
        <div
          className={`absolute bg-blackB w-full border-[1px] border-cream rounded-lg shadow-[0px_4px_4px_rgba(0,0,0,0.25)] py-2  transition-all duration-75 ease-linear ${
            !dropDownContext ? "invisible -z-50" : " z-50 visible"
          } ${details.disable ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
        >
          {details.options.map((opt) => (
            <div
              className="hover:bg-blackA hover:text-cream w-full grid items-center text-sm h-6 pr-2 2xl:text-base 2xl:h-12  2xl:pr-6 "
              key={opt.id}
              title={opt.label}
              id={opt.id}
              onClick={() => handleClick(opt.id, opt.label)}
            >
              {opt.label}
            </div>
          ))}
        </div>
      </div>
    );
  };

  export default DropDown;