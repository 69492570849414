import axios from 'axios';
import { useEffect, useState } from 'react';

const UseInfinit = (api) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [ai, setAi]=useState([])
    const[hasMore, setHasMore] = useState(false)
    useEffect(()=>{
        setLoading(true)
        setError(false)
        axios.get(api,{
            
        })
        .then((res) => {
            setAi(res.data)
            setHasMore((res.data.next!== null) ? true : false)
            setLoading(false)
        }).catch((error) => {
            // Handle error
            console.error("Error fetching data:", error);})
    },[ api])
    return {loading,hasMore,ai, error}
}

export default UseInfinit;
