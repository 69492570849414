import React, { useCallback, useEffect, useRef, useState } from "react";
import DropDown from "../common/dropdown/dropDown";
import Header from "../common/header";
import MagazineList from "../Components/magazines/magazineList";
import Spinner from "../common/spinner";
import SearchForm from "../Components/searchBar/searchForm";
import UseInfinit from "../common/useInfinit";

const Neveshtari = () => {
  const dropDownDetails = [
    {
      title: "عنوان",
      id: "magazine",
      options: [
        { id: 1, label: "خوشه" },
        { id: 2, label: "رودکی" },
        { id: 3, label: "رادیو ایران" },
        { id: 4, label: "صور اسرافیل" },
        { id: "", label: "همه" },
      ],
      disable: false,
    },
    {
      title: "مرتب‌سازی",
      id: "sort",
      options: [
        { id: "D", label: "آخرین ها" },
        { id: "A", label: "اولین ها" },
        { id: "", label: "همه" },
      ],
      disable: false,
    },
    {
      title: "موضوع",
      id: "category",
      options: [
        { id: 1, label: "سینما" },
        { id: 2, label: "تیاتر" },
        { id: 3, label: "موسیقی" },
        { id: 4, label: "شعر" },
        { id: 5, label: "داستان" },
        { id: 6, label: "تجسمی" },
        { id: 7, label: "تاریخی" },
        { id: 8, label: "مصاحبه" },
        { id: "", label: "همه" },
      ],
      disable: true,
    },
  ];

  const [selectedFilters, setSelectedFilters] = useState({});
  const [data, setData] = useState();
  const [urlDynamic, setUrlDynamic] = useState();
  
  const handleFilterSelect = (title, optionId) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [title]: optionId,
    }));
  };

  useEffect(() => {
    fetchData();
  }, [selectedFilters]);

  const fetchData = () => {
    const queryParams = new URLSearchParams(selectedFilters);
    const url = `https://api.jaygasht.ir/issues/?${queryParams.toString()}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // Update the data state with the fetched data
        setData(data.results);
        setUrlDynamic(data.next);

      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching data:", error);
      });
  };

  const { ai, loading, hasMore, error } = UseInfinit(urlDynamic);

  const observer = useRef();
  const lastMagazinesListRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setUrlDynamic(ai.next);
          setData([...data, ...ai.results]);
        }
      }, {rootMargin: "300px"});
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, ai, selectedFilters]
  );

  return (
    <div className="bg-blackB w-screen max-w-[1000px] relative text-whiteA 2xl:max-w-[2000px] ">
      <div className="relative z-10 ">
        <Header />
      </div>
      <h1 className="flex justify-center animate-jump-in mb-5 mt-4 2xl:mb-8 h-4 2xl:h-6 font-semibold text-base 2xl:font-bold 2xl:text-2xl 2xl:leading-[38px] text-cream">
        نوشتاری
      </h1>
      {/* DROPDOWNN-SEARCH */}
      <div className="grid grid-cols-3 2xl:grid-cols-4 gap-x-2 gap-y-4 sm:gap-x-3 2xl:gap-x-8 mx-6 2xl:mx-[192px] mb-10">
        {/* search */}
        <div className=" col-span-full border-[1px] border-cream rounded-lg shadow-[0px_4px_4px_rgba(0,0,0,0.25)] 2xl:col-span-1 h-8 2xl:h-12">
          <SearchForm />
        </div>
        {/* search */}
        {dropDownDetails.map((details) => {
          return (
            <DropDown
              key={details.title}
              details={details}
              onFilterSelect={handleFilterSelect}
            />
          );
        })}
      </div>
      {/* DROPDOWN-SEARCH */}
      
      {data && (
        <div className="px-[16px] 2xl:px-[72px] animate-fade-left">
          <MagazineList
            data={data}
            lastMagazinesListRef={lastMagazinesListRef}
            isPending={loading}
            error={error}
            hasMore
          />
        </div>
      )}
      {loading && (
        <div className="flex flex-grow justify-center items-center h-screen absolute top-1/2 inset-x-0">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default Neveshtari;
