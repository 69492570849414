import React from "react";
import "./button.css";
import { MdPlayArrow, MdPause } from "react-icons/md";
import {
  MdSkipNext,
  MdSkipPrevious,
  MdReplay10,
  MdForward10,
} from "react-icons/md";
function Button({ play, isPlaying, onNextPrev, id, fastForward}) {
  return (
    <div className="flex justify-between items-center w-[170px] 2xl:w-[260px] ">
      <div onClick={(e)=> fastForward(e ,10)}>
        <MdForward10
          className={`cursor-pointer text-xl ${
            isPlaying ? "text-[#2B2B2B]" : "text-[#E7DB9D]"
          }`}
        />
      </div>
      <div onClick={() => {onNextPrev(id, 1);}}>
        <MdSkipNext
          className={`cursor-pointer text-[22px] 2xl:text-[31px] ${
            isPlaying ? "text-[#2B2B2B]" : "text-[#E7DB9D]"
          }`}
        />
      </div>
      <div
        className={`btn-container w-6 h-6 rounded-[50%] 2xl:w-10 2xl:h-10 ${
          isPlaying ? "bg-[#2B2B2B]" : "bg-[#E7DB9D]"
        }`}
      >
        <div>
          {/* <div onClick={play} className={isPlaying ? 'btn-stop' : 'btn-play'}></div> */}
          {isPlaying ? (
            <div onClick={play}>
              <MdPause className="cursor-pointer fill-cream 2xl:text-[31px] " />
            </div>
          ) : (
            <div onClick={play}>
              <MdPlayArrow className="cursor-pointer text-lg 2xl:text-[31px] fill-blackB" />
            </div>
          )}
        </div>
      </div>
      <div onClick={() => {onNextPrev(id, -1) }}>
        <MdSkipPrevious
          className={`cursor-pointer text-[22px] 2xl:text-[31px] ${
            isPlaying ? "text-[#2B2B2B]" : "text-[#E7DB9D]"
          }`}
        />
      </div>
      <div onClick={(e)=> fastForward(e ,-10)}>
        <MdReplay10
          className={`cursor-pointer text-xl ${
            isPlaying ? "text-[#2B2B2B]" : "text-[#E7DB9D]"
          }`}
        />
      </div>
    </div>
  );
}
export default Button;
