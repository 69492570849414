import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Zoom, Navigation, Pagination } from "swiper/modules";
// import 'swiper/swiper-bundle.css';
import "./styles.css";

export default function SwiperComponent({ pages, handleNextPrev }) {
  return (
    <>
      <Swiper
        dir="ltr"
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        zoom={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[Zoom, Navigation, Pagination]}
        className="mySwiper"
        onReachEnd={() => handleNextPrev(pages[pages.length - 1].next_page)}
        onNavigationPrev={() => handleNextPrev(pages[0].previous_page)}
      >
        {pages.map((page) => {
          return (
              <SwiperSlide key={page.page_number}>
                <div className="swiper-zoom-container ">
                  <img
                    src={page.image}
                    className="w-screen md:w-[640px] md:h-[640px] xl:w-[1024px] xl:h-[1024px]"
                    alt={
                      page.magazine_title +
                      " سال " +
                      page.issue_year +
                      " شماره " +
                      page.issue_number +
                      " صفحه " +
                      page.page_number
                    }
                  />
                </div>
              </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
