import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SearchList from './Components/searchBar/searchList';
import IssueItem from "./Components/issue/issueItem";
import Landing from "./common/landing";
import Home from "./pages/home"
import Shenidari from "./pages/shenidari";
import Neveshtari from "./pages/neveshtari";
import About from "./pages/about";






function App() {
  return (
    <Router>
      {/* <Landing/> */}
      {/* <Home/> */}
      <Routes>

        <Route path="/" element={<Home/>}/>
        <Route path="/shenidari" element={<Shenidari/>}/>
        <Route path="/neveshtari" element={<Neveshtari/>}/>
        <Route path="/searchList" element={<SearchList/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/issues/:id" element={<IssueItem/>} />



        {/* <Route path="/magazines" element={<MagazinesList />}/>
        <Route path="/magazines/:id" element={<MagazineItem />} />
        <Route path="/issues/:id" element={<IssueItem/>} />
        <Route path="/searchList" element={<SearchList/>} />
        <Route path="/radio" element={<RadioKhoushe/>}/> */}



        {/* <Route path="/searchItem/4" element={<PDFReader/>} /> */}
      </Routes>
    </Router>
  );
}

export default App;
