import React from "react";
import "../Components/AudioPlayer/controls/button.css";
import Header from "../common/header";
import JaygashtPlayer from "../Components/radio/Radio";
import Banner from "../common/banner";
import useFetch from "../common/useFetch";
import Spinner from "../common/spinner";

const Home = () => {
  const { data } = useFetch(
    "https://api.jaygasht.ir/episodes/"
  );
  return (
    <div className="bg-blackB w-screen relative  text-whiteA ">
      {/* sideBar */}
      <div className="relative z-10">
        <Header />
      </div>
      
      {/* end of sideBar */}
      {/* frame and text */}
      <div className=" animate-fade-left">
      <Banner />
      </div>
      {/* frame and text */}
      <div className="animate-fade-left h-[17px] mr-4 2xl:mr-[72px] my-4 font-semibold text-cream 2xl:text-whiteA flex items-center text-right 2xl:text-2xl">
        جدیدترین‌ها
      </div>
      {!data && <div className="flex flex-grow justify-center items-center h-screen absolute top-1/2 inset-x-0"><Spinner /></div>}
      {data && <div className="relative w-screen z-20 mt-6 animate-fade-left"> <JaygashtPlayer podcast={data.results} layoutDiv={true} /></div>}
    </div>
  );
};

export default Home;
