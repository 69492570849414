import React from "react";
import { Link } from "react-router-dom";

const MagazineList = ({data, error, lastMagazinesListRef, hasMore}) => {
  // const magazines = [
  //   {
  //     title: "صور اسرافیل",
  //     editor: "شاملو",
  //     year: "سال ۴۸",
  //     cover: require("../../common/pic/cover1.jpg"),
  //   },
  //   {
  //     title: "خوشه",
  //     editor: "شاملو",
  //     year: "سال ۴۸",
  //     cover: require("../../common/pic/cover3.jpg"),
  //   },
  //   {
  //     title: "رودکی",
  //     editor: "شاملو",
  //     year: "سال ۴۸",
  //     cover: require("../../common/pic/cover5.jpg"),
  //   },
  //   {
  //     title: "سخن",
  //     editor: "شاملو",
  //     year: "سال ۴۸",
  //     cover: require("../../common/pic/cover6.jpg"),
  //   },
  // ];
  return (
    <>
    <div className="  grid grid-cols-1 xs:grid-cols-2 gap-4 2xl:grid-cols-4 ">
      {error && <div>{error}</div>}    
      {data && data.map((post) => {
        
        return (
          <div ref={lastMagazinesListRef} key={post.id} className=" text-whiteD animate-fade-left">
            <Link to={`/issues/${1}`} state={{firstPage : post.first_page}}>
            <div className="flex  py-1 px-7 2xl:px-12 2xl:py-2 justify-center bg-[#D9D9D9] bg-opacity-[7%] rounded-lg border-[1px] border-cream border-opacity-40 shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
              <img src={post.thumbnail} alt={post.magazine_title} />
            </div>
            </Link>
            
              <h2 className=" flex items-center pt-2 justify-start text-cream text-sm 2xl:pt-5 2xl:text-base 2xl:leading-[20px] font-semibold ">
                {post.magazine_title}
              </h2>
              <p className=" text-xs leading-[19px] my-2 2xl:text-base flex items-center justify-start">شماره {post.number}</p>
              <p className=" text-xs leading-[19px] mb-2 2xl:text-base flex items-center justify-start">سال {post.year}</p>
          </div>
        );
      })}
    </div>
     {hasMore &&  
      <div className="h-10" ref={lastMagazinesListRef} ></div>
    }
    </>
  );
};

export default MagazineList;
