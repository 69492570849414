import React from 'react';

import Img from './pic/spinner.svg'
const Spinner = () => {
    return (
       <div className='flex justify-center w-20 h-20'>
        <img src={Img} alt="" />
       </div>
    );
}

export default Spinner;
