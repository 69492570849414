import React from "react";
import { Link } from "react-router-dom";

import {
  MdFullscreen,
  MdOutlineFileDownload,
  MdOutlineShare,
} from "react-icons/md";

const SearchItem = ({error, data, query}) => {


  return (
    <div className="mx-4 2xl:mx-24 my-8 2xl:my-11">
        <div className="grid grid-cols-1">
          {error && <div>{error}</div>}
          {data &&
            data.map((post) => {
              return (
                <div key={post.id}>
                  <div className="flex justify-between 2xl:mx-[72px]">
                  <div className=" text-whiteD flex justify-between">
                    <Link to={`/issues/${1}`} state={{ firstPage: post.id }}>
                      <div className="flex  py-1 px-7 2xl:px-9 2xl:py-2 justify-center bg-[#D9D9D9] bg-opacity-[7%] rounded-lg border-[1px] border-cream border-opacity-40 shadow-[0px_4px_4px_rgba(0,0,0,0.25)] 2xl:min-w-[208px]">
                        <img src={post.thumbnail} alt={post.magazine_title + " " + query} />
                      </div>
                    </Link>

                    <div className="flex mr-4 flex-col justify-between min-w-[62px] 2xl:min-w-[288px] ">
                      <h2 className=" flex items-center justify-start text-cream text-sm  2xl:text-xl 2xl:leading-[20px] font-semibold ">
                        {post.magazine_title}
                      </h2>
                      <div className="text-whiteD flex flex-col gap-2">
                        <p className=" text-sm leading-[22px] 2xl:text-base flex items-center justify-start m-0">
                          شماره {post.issue_number}
                        </p>
                        <p className=" text-sm leading-[22px] 2xl:text-base flex m-0">
                          سال {post.issue_year}
                        </p>
                        <p className=" text-sm leading-[22px] 2xl:text-base flex items-center justify-start m-0">
                          صفحهٔ {post.page_number}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-between text-cream mr-[53px] 2xl:mr-[576px]">
                    <div className=" bg-blackA w-10 h-10 flex items-center justify-center shadow-[0_4px_4px_0_rgba(0,0,0,0.25)] rounded-lg p-2 2xl:min-w-[208px] 2xl:min-h-[56px]">
                      <p className="hidden 2xl:flex-grow 2xl:flex 2xl:justify-center m-0 2xl:items-center text-whiteA text-base font-medium"> دانلود</p>
                      <MdOutlineFileDownload className="w-6 h-6 2xl:w-10 2xl:h-10  flex-none" />
                    </div>
                    <Link to={`/issues/${1}`} state={{ firstPage: post.id }} className=" bg-blackA text-cream no-underline hover:text-cream w-10 h-10 flex items-center justify-center shadow-[0_4px_4px_0_rgba(0,0,0,0.25)] rounded-lg p-2 2xl:min-w-[208px] 2xl:min-h-[56px]">
                    <p className="hidden 2xl:flex-grow 2xl:flex 2xl:justify-center m-0 2xl:items-center text-whiteA text-base font-medium"> مشاهده</p>
                      <MdFullscreen className="w-6 h-6 2xl:w-10 2xl:h-10  flex-none" />
                    </Link>
                    <div className=" bg-blackA w-10 h-10 flex items-center justify-center shadow-[0_4px_4px_0_rgba(0,0,0,0.25)] rounded-lg p-2 2xl:min-w-[208px] 2xl:min-h-[56px]">
                    <p className="hidden 2xl:flex-grow 2xl:flex 2xl:justify-center m-0 2xl:items-center text-whiteA text-base font-medium"> اشتراک‌گذاری</p>
                      <MdOutlineShare className="w-5 h-5 2xl:w-10 2xl:h-10  flex-none" />{" "}
                    </div>
                  </div>
                </div>
                <div className=" bg-cream bg-opacity-20 w-[66%] h-[3px] my-[16px] 2xl:my-[32px] mx-auto"></div>
                </div>
              );
            })}
        </div>
      </div>
  );
};

export default SearchItem;
