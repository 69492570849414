import { useState } from "react"
import { MdSearch } from "react-icons/md"
import { useNavigate } from "react-router"
const SearchForm = (props) => {
    const [query, setQuery] = useState('')
    const navigate = useNavigate()
    const searchHandle = () => {
      navigate('/searchList?query=' + query)
    }
    return ( <form
        className="relative w-full  rounded-md  mx-0  grid grid-cols-[7fr_1fr] items-center"
        onSubmit={searchHandle}
        
      >
        <div id="" className="relative">
          <label
            htmlFor="form1"
            className="absolute -top-3 right-3 text-sm 2xl:text-base bg-blackB text-whiteC  text-center h-4 px-2"
          >
            جستجو
          </label>
          <input
            type="text"
            id="form1"
            name="query"
            placeholder={props.placeholder}
            onChange={event => setQuery(event.target.value)}
            className="bg-blackB pt-2 w-full px-2 text-xs 2xl:mt-2 outline-none placeholder-white border-0 2xl:text-sm text-center"
          />
        </div>
        <div className=" w-7 h-7">
          <MdSearch className="  w-5 h-5 2xl:w-7 left-2 top-[5px] 2xl:top-[10px] 2xl:h-7 absolute text-whiteC " />
          <input
            type="submit"
            value=""
            className=" w-8 h-full absolute top-1 left-2"
          />
        </div>
      </form> );
}
 
export default SearchForm;