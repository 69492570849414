import React, { useState } from "react";
import AudioItem from "./audioItem";
import CostumPlayer from "../AudioPlayer/CostumPlayer";
import { MdClose } from "react-icons/md";

const JaygashtPlayer = ({ podcast, lastAudio, hasMore, layoutDiv }) => {
  // AUDIO PLAYER PACK
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [currentSong, setCurrentSong] = useState("");
  const [playListOrder, setPlayListOrder] = useState();
  const [harmonization, setHarmonization] = useState();
  const [playerShow, setPlayerShow] = useState(false);
  const [info, setInfo] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState('');
  const [channel, setChannel] = useState('خوشه')
  const handleInfo = (id, channel, title) => {
    if (!info) {
      setInfo(true);
      setDescription(id);
      setChannel(channel)
      setTitle(title)
    } else {
      setInfo(false);
    }
  };
  const func = (data) => {
    setHarmonization(data);
  };
  function clickAudioItem(id, n) {
    if (!playerShow) {
      setPlayerShow(true);
    } else {
    }
    if (currentSongIndex === podcast.length - 1 && n === 1) {
      setCurrentSongIndex(0);
      setCurrentSong(podcast[0].file);
    } else if (currentSongIndex === 0 && n === -1) {
      setCurrentSongIndex(podcast.length - 1);
      setCurrentSong(podcast[podcast.length - 1].file);
    } else {
      setCurrentSongIndex(id + n);
      setCurrentSong(podcast[id + n].file);
      playListOrder
        ? currentSong === podcast[id + n].file
          ? setPlayListOrder(false)
          : setPlayListOrder(true)
        : setPlayListOrder(true);
    }
  }
  // END OF AUDIO PLAYER PACK

  return (
    <div className="bg-blackB w-full h-screen mt-0 relative text-whiteA">
      {/* CARDS- AUDIO PLAYER PACK-NUMBER2 */}
      <div className=" grid grid-cols-1 lg:grid-cols-2  gap-4 2xl:grid-cols-5 2xl:mx-[72px]">
        {podcast.map((post, index) => {
          return (
            <AudioItem
              key={post.id}
              post={post}
              id={index}
              clickAudioItem={clickAudioItem}
              handleInfo={handleInfo}
              icon={
                playListOrder && currentSong === post.file && harmonization
                  ? true
                  : false
              }
            />
          );
        })}
      </div>
      {/* END OF CARDS */}
      {/* INFO */}
      <div
        className={`fixed z-50 inset-x-0  -top-[235px] bottom-0 2xl:absolute pt-10 px-10 pb-[17px] 2xl:top-24 2xl:inset-x-[304px] animate-fade-left 2xl:h-fit bg-blackA backdrop-blur-sm opacity-95 rounded-lg ${
          info ? "block" : "hidden"
        }`}
      >
        <div className="flex items-center">
          <MdClose
            onClick={() => handleInfo()}
            className=" cursor-pointer flex-none w-4 h-4 font-semibold text-cream"
          />
          <h3 className="flex-grow text-center w-full  font-semibold text-base text-cream leading-5">
            {channel}
          </h3>
        </div>
        <h3 className="flex-grow my-10 text-center w-full  font-semibold text-xs text-cream leading-5">
            {title}
          </h3>
        <div className=" mt-6 overflow-x-hidden font-semibold text-base leading-8 text-whiteC">
          {description}
        </div>
      </div>
      {/* INFO */}
      {hasMore && <div className="h-full" ref={lastAudio}></div>}
      {layoutDiv && <div className="h-full" ></div>}
      {playerShow ? (
        <div className="w-screen animate-fade-up sticky inset-x-0 bottom-0 ">
          <CostumPlayer
            src={currentSong}
            podcast={podcast}
            playListOrder={playListOrder}
            func={func}
            onNextPrev={clickAudioItem}
            currentSongIndex={currentSongIndex}
          />
        </div>
      ) : (
        ""
      )}
      {/* END OF AUDIO PLAYER PACK-NUMBER2 */}
    </div>
  );
};

export default JaygashtPlayer;
