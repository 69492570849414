
import Header from "../common/header";

const About = () => {
  return (
    <div className="bg-blackB max-w-full  relative 2xl:mx-[72px]  text-whiteA ">
      {/* sideBar */}
      <div className="relative  z-10">
        <Header />
      </div>
      <h1 className="flex justify-center animate-jump-in mb-5 mt-4 2xl:mb-8 h-4 2xl:h-6 font-semibold text-lg 2xl:font-bold 2xl:text-2xl 2xl:leading-[38px] text-cream">
        درباره ما
      </h1>
      {/* end of sideBar */}
      <div className=" max-w-full p-4 2xl:px-[72px]">
        <div className="  2xl:mx-[72px] leading-[26px] 2xl:text-lg 2xl:leading-9 animate-fade-left">
          ما در <b>جایگشت</b> از دالان گنجینهٔ نشریات، دریچه‌ای به گذشته باز می‌کنیم.
          هدف ما جاری کردن دانش نهفته در این متون به متن زندگی امروز است. با این
          امید که ابزارهای نو با ایجاد دسترسی آسان‌تر، امکان نگاهی نو را در
          پژوهش‌های هنری و ادبی بگشاید.
          <br />
        </div>
        <h2 className="animate-fade-left text-lg 2xl:text-2xl font-black h-[17px]  my-4  text-cream  flex items-center text-right">
          <b>سفر ما</b>
        </h2>
        <div className="relative  2xl:mx-[72px] leading-[26px] 2xl:text-lg 2xl:leading-9 animate-fade-left">
          تیم جایگشت از اوایل سال <b>۱۴۰۰</b>  با اسکن چند عنوان نشریه آغاز به کار کرد،
          با این امید که خطوط خاک خورده در متن این صفحات در طی زمان از پوسیدگی
          کاغذ در امان بمانند. مطالب نابی که حین همین تورق‌ها پیدا می‌شد اشتیاق
          ما را به ماندگار کردن آن‌ها بیشتر کرد. سال <b>۱۴۰۱</b> با هدف شنیداری کردن
          گزیدهٔ این مطالب، تیم جایگشت به گسترش همراهانش در زمینهٔ ساخت برنامهٔ
          رادیویی پرداخت تا در نهایت از ابتدای سال <b>۱۴۰۲</b> با انتشار دو برنامهٔ
          «<b className=" from-black">رادیو خوشه</b>» و «<b className=" from-black">خوشه‌چین</b>» در میزبان‌های پادکست، دریچه‌ای دیگر برای
          نگاه به هنر و ادبیات در گذر زمان باز کند. ساخت یک برنامه‌ٔ منظم
          رادیویی نیازمند داشتن محتوای مناسب به تعداد بسیار است. از این رو برای
          آسان‌سازی دسترسی به مطالب درباره‌ٔ اشخاص و موضوعات مشخص، به راهی برای
          جستجوی سریع در میان حجم بالای مطالب نیاز داشتیم. برای رسیدن به این
          هدف، از فناوری نویسه‌گردانی برای استخراج متون از تصاویر استفاده کردیم.
          یک موتور جستجو برای داده‌های متنی ذخیره شده گسترش دادیم و در ادامه قصد
          داریم تا با برچسب‌گذاری موضوعی این صفحات، یک ابزار پژوهش جامع برای
          جستجو در صفحات اسکن شده ارائه دهیم.
          <br />
        </div>
        <h2 className="animate-fade-left h-[17px] text-lg font-black my-4 text-cream flex items-center text-right 2xl:text-2xl">
          <b className="font-black">آنچه ارائه می‌دهیم</b>
        </h2>
        <div className="relative  2xl:mx-[72px] leading-[26px] 2xl:text-lg 2xl:leading-9 animate-fade-left">
          <b><h3>۱. محتوای نوشتاری:</h3></b>سایت ما آرشیو گستردهٔ
          دیجیتالی از نشریات قدیمی را در خود دارد که با کیفیت بالا اسکن شده و به
          شکل قابل پیمایش و جستجو ذخیره شده‌است. اگر شما یک محقق دانشگاهی،
          علاقه‌مند به تاریخ، هنر، ادبیات، یا صرفاً فردی کنجکاو در مورد
          گذشته‌اید، در جایگشت مطالب جالب و کمیابی پیدا خواهید کرد.
          <br />
          <b ><h3>۲. قابلیت جستجو و فیلتر:</h3></b>  ما بر
          آسان‌سازی دسترسی تمرکز داریم. قابلیت جستجوی متنی در کنار فیلترهای
          متفاوت، سرعت دسترسی شما به مطالب مورد نظرتان را بالا خواهد برد، همان
          طور که ما را در یافتن و گزینش مطالب برای محتوای شنیداری کمک کرده‌است.{" "}
          <br />
          <b><h3>۳. محتوای شنیداری:</h3></b> اگر به برنامه‌های رادیویی و موضوعات مربوط به
          سینما، تئاتر، ادبیات و دیگر هنرها علاقمندید، محتوای شنیداری ما که
          گزیده‌ای از بایگانی نشریات جایگشت است را به شما پیشنهاد می‌دهیم. از
          گویندگی تا انتخاب موسیقی و تدوین این برنامه‌ها، حاصل کار و پشتکار
          گروهی از هنرمندان جوان است که شیفتگی‌شان به هنر و ادبیات را در دقت و
          ظرافت اجرای این برنامه‌ها خواهید شنید.
          <br />
        </div>
        <h2 className="animate-fade-left h-[17px] text-lg my-4 font-semibold text-cream flex items-center text-right 2xl:text-2xl">
          چشم‌انداز ما
        </h2>
        <div className="relative pb-10 2xl:mx-[72px] leading-[26px] 2xl:text-lg 2xl:leading-9 animate-fade-left">
          در جایگشت باور داریم که تاریخ، هنر و ادبیات از گرد و غبار قفسه‌ها باید
          بیرون کشیده شود و با ابزارهای امروزی آشتی کند تا با نگاه تازه به این
          گذشتهٔ دراز، مسیر پیش رو روشن‌تر شود. هدف ما پرکردن شکاف گذشته و
          آینده، کنجکاوی برانگیختن، الهام بخشیدن به اندیشه‌های نو و رسیدن به
          درکی عمیق‌تر از میراث جمعی‌ست. در ادامه با جمع‌آوری آرشیوهای متنی
          بیشتر و استفاده از شکل‌های دسترس‌پذیرتر و هماهنگ‌تر با زیست افراد
          امروزی، امیدواریم به چشم‌اندازهای وسیع‌تر برسیم.
          <br />
        </div>
      </div>
    </div>
  );
};

export default About;
