import React from 'react';

const Banner = () => {
    return (
        <div className="grid grid-cols-1 gap-0 2xl:grid-cols-2 2xl:my-[32px] 2xl:mx-[72px]">
        <div className=" flex justify-center 2xl:order-last 2xl:justify-end">
          <div className=" box-border rounded-lg w-[225px] h-[233px] 2xl:w-[464px] 2xl:h-[424px]   bg-[#3D3131]">
            <img
              src={require("../common/pic/Picsart_23-03-17_01-35-52-163.jpg")}
              className="w-full w-max-full h-full rounded-lg"
              alt=""
            />
          </div>
        </div>
        <div className="mt-4 2xl:flex-col 2xl:space-y-12">
          <h1 className="text-[#E7DB9D] font-schehrazade flex justify-center text-[36px] leading-[60px] 2xl:text-[64px] 2xl:font-bold 2xl:leading-[108px] 2xl:justify-start 2xl:items-center 2xl:w-[266px] 2xl:h-[44px]">
            جایگشت
          </h1>
          <h2 className=" flex justify-center text-[24px] leading-[38px] 2xl:justify-start 2xl:items-center 2xl:w-[491px] 2xl:h-100px 2xl:font-medium 2xl:text-[32px] 2xl:leading-[50px]">
             نگاهی تازه به نشریات قدیمی
             <br/>
             در زمینهٔ هنر و ادبیات
          </h2>
        </div>
      </div>
    );
}

export default Banner;
