import React from "react";
import { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdClose } from "react-icons/md";
import logo from "./pic/logo.svg";
import { Link } from "react-router-dom";
const Header = () => {
  const [toggle, setToggle] = useState(false);

  function handler() {
    !toggle ? setToggle(true) : setToggle(false);
  }
  return (
    <div className="block z-50 bg-blackB min-w-[320px] text-whiteA 2xl:flex 2xl:items-center 2xl:justify-start pt-4 pr-4 pb-4 2xl:pt-8  2xl:pr-[72px]">
      <div className=" flex ">
        <div
          className=" 2xl:hidden absolute flex-none border-[0.5px] border-cream cursor-pointer w-8 h-8 flex  p-2 rounded-lg bg-blackA"
          onClick={() => handler()}
        >
          {!toggle ? <RxHamburgerMenu /> : <MdClose />}
        </div>
        <div className="w-14 h-8 2xl:w-[94px] 2xl:h-14 flex-grow flex justify-center">
        <Link to={'/'}>
        <img
          src={logo}
          alt="logo"
          className="cursor-pointer w-full h-full"
        /></Link>
          </div>
        
      </div>

      <div
        className={`${
          !toggle
            ? " absolute hidden"
            : " flex fixed inset-y-0 bg-blackB  h-screen animate-fade-left right-0 "
        } 2xl:flex  2xl:right-[239px] 2xl:items-center 2xl:flex-grow `}
      >
        <ul className="w-[200px] 2xl:flex 2xl:w-auto  2xl:gap-[72px]">
          <li className=" text-cream text-2xl flex justify-between pr-4 items-center h-10 leading-[34px] bg-blackA w-[200px] 2xl:hidden">
            جایگشت
            <MdClose onClick={() => handler()} />
          </li>
          <Link to={"/shenidari"} className="li-home">
            شنیداری
          </Link>
          <div className="w-[168px] mr-4 h-[1px] bg-cream opacity-50 2xl:hidden"></div>
          <Link to={"/neveshtari"} className="li-home">
            نوشتاری
          </Link>
          <div className="w-[168px] mr-4 h-[1px] bg-cream opacity-50 2xl:hidden"></div>
          <Link to={"/about"} className="li-home">
            درباره ما
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Header;
