import { useState, useRef, useEffect } from "react";
import Slider from "./slider/Slider";
import "./index.css";
import Button from "./controls/Button";
import { MdVolumeOff, MdVolumeUp, MdOutlineShare } from "react-icons/md";

function CostumPlayer({
  src,
  playListOrder,
  func,
  onNextPrev,
  currentSongIndex,
  podcast
}) {
  const [percentage, setPercentage] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [volume, setVolume] = useState(50);
  const [mute, setMute] = useState(false);
  const audioRef = useRef(src);
  func(isPlaying);
  useEffect(() => {
    if (src === "" || playListOrder === false) {
      setIsPlaying(false);
      audioRef.current.pause();
    } else {
      setIsPlaying(true);
      audioRef.current.play();
      setPlaybackRate(1);
    }
  }, [src, playListOrder]);
  // Update the playback rate when the playbackRate state changes
  useEffect(() => {
    audioRef.current.playbackRate = playbackRate;
  }, [playbackRate]);
  // VOLOUM
  const handleVolumeChange = (event) => {
    const { value } = event.target;
    setVolume(value);
    audioRef.current.volume = value / 100;
  };
  const volumeOff = () => {
    if (audioRef.current.muted) {
      audioRef.current.muted = false;
      setMute(false);
    } else {
      audioRef.current.muted = true;
      setMute(true);
    }
  };
  // CHANGE CURRENT TIME BY PROGRESS BAR AND FAST-FORWARD-BACKWARD
  const onChange = (e, seconds = 0) => {
    if (seconds === 10) {
      if (audioRef.current.currentTime + seconds < duration) {
        audioRef.current.currentTime += seconds;
        setCurrentTime(audioRef.current.currentTime);
      } else {
        audioRef.current.currentTime = duration;
        setCurrentTime(duration);
      }
    } else if (seconds === -10) {
      if (audioRef.current.currentTime + seconds > 0) {
        audioRef.current.currentTime += seconds;
        setCurrentTime(audioRef.current.currentTime);
      } else {
        audioRef.current.currentTime = 0;
        setCurrentTime(0);
      }
    } else {
      const audio = audioRef.current;
      audio.currentTime = (audio.duration / 100) * e.target.value;
      setPercentage(e.target.value);
    }
  };
  // CONTROL PLAY AND PAUSE
  const play = () => {
    const audio = audioRef.current;

    if (!isPlaying) {
      setIsPlaying(true);
      audio.play();
    }

    if (isPlaying) {
      setIsPlaying(false);
      audio.pause();
    }
  };

  const getCurrDuration = (e) => {
    const percent = (
      (e.currentTarget.currentTime / e.currentTarget.duration) *
      100
    ).toFixed(2);
    const time = e.currentTarget.currentTime;

    setPercentage(+percent);
    setCurrentTime(time.toFixed(2));
  };
  // duration to hms
  function secondsToMinSec(seconds) {
    if (!seconds) return "00:00";

    let min = Math.floor(seconds / 60);
    let sec = Math.floor(seconds % 60);

    if (min < 10) {
      min = `0${min}`;
    }
    if (sec < 10) {
      sec = `0${sec}`;
    }

    return `${min}:${sec}`;
  }

  // end
  return (
    <div>
      <div
        className={` m-0 py-2 box-border relative 2xl:px-[72px] 2xl:grid 2xl:place-items-center h-[90px] 2xl:h-[104px] 2xl:py-0 ${
          isPlaying ? "bg-[#E7DB9D] transition-all ease-in-out duration-1000" : "bg-[#2B2B2B] transition-all ease-in-out duration-1000"
        }`}
      >
        <div className="px-4 flex justify-between h-[30px] 2xl:absolute 2xl:right-[72px] 2xl:top-6 2xl:h-14  2xl:justify-start">
          <div className=" hidden 2xl:flex w-14 h-14 rounded-lg">
            <img
              src={podcast[currentSongIndex].cover}
              className="w-full h-full rounded-lg"
              alt=""
            />
          </div>
          <div>
            <h2
              className={`text-[13px] leading-[14px]  font-medium overflow-clip h-[13px] 2xl:w-[375px] 2xl:h-[21px] 2xl:text-base 2xl:mr-4 ${
                isPlaying ? "text-blackB" : "text-cream"
              }`}
            >
              {podcast[currentSongIndex].title}
            </h2>
            <h3
              className={`text-[12px] leading-[17px] h-[13px] 2xl:hidden ${
                isPlaying ? "text-blackA" : "text-time"
              }`}
            >
              {podcast[currentSongIndex].channel}
            </h3>
          </div>

          
          <div className="2xl:hidden">
            <div>
              <MdOutlineShare
                className={`w-[14px] h-[14px] ${
                  isPlaying ? "text-blackA" : "text-cream"
                } 2xl:w-6 opacity-80  `}
              />
            </div>
          </div>
        </div>
        {/* progress bar in sm */}
        <div className="z-50 w-full px-4 mt-3 2xl:hidden">
          <Slider
            percentage={percentage}
            onChange={onChange}
            isPlaying={isPlaying}
          />
        </div>
        {/* End of progress bar in sm */}
        <div
          className={` flex relative items-start justify-between px-4 pt-[6px] w-screen 2xl:w-full h-6 2xl:h-[21px] 2xl:pt-5  rounded-lg `}
        >
          <h3
            className={`hidden text-base h-[21px] mr-[72px] min-w-[100px] ml-[59px] 2xl:flex ${
              isPlaying ? "text-blackA" : "text-time"
            }`}
          >
            {podcast[currentSongIndex].channel}
          </h3>
          <div className="flex relative items-center justify-between w-screen 2xl:w-[800px] h-6 rounded-lg">
            <div
              className={`text-[10px] leading-4 w-[26px] h-4 2xl:text-[14px] ${
                isPlaying ? "text-[#1F1F1F] opacity-80" : "text-cream"
              }`}
            >
              {secondsToMinSec(duration)}
            </div>
            <div className="2xl:hidden">
              <Button
                play={play}
                isPlaying={isPlaying}
                onNextPrev={onNextPrev}
                id={currentSongIndex}
                fastForward={onChange}
              />
            </div>
            <div className="hidden relative 2xl:min-w-[680px] 2xl:flex">
              <Slider
                percentage={percentage}
                onChange={onChange}
                isPlaying={isPlaying}
              />
              <div className="2xl:flex 2xl:absolute 2xl:left-1/3 2xl:w-[260px] 2xl:-top-14">
            <Button
              play={play}
              isPlaying={isPlaying}
              onNextPrev={onNextPrev}
              id={currentSongIndex}
              fastForward={onChange}
            />
          </div>
            </div>
            <div
              className={`text-[10px] leading-4 w-[26px] h-4 2xl:text-[14px] ${
                isPlaying ? "text-[#1F1F1F] opacity-80" : "text-cream"
              }`}
            >
              {secondsToMinSec(currentTime)}{" "}
            </div>
          </div>
          <div className="mr-[122px] w-24 h-[14px] justify-between hidden 2xl:flex">
            <div>
              <MdOutlineShare
                className={`hidden 2xl:flex w-[14px] h-[14px]  ${
                  isPlaying ? "text-blackA" : "text-cream"
                }`}
              />
            </div>
            <div
              className={`hidden 2xl:block relative w-6 cursor-pointer group ${
                isPlaying ? "text-[#2B2B2B]" : "text-cream"
              }`}
            >
              <div className="speed hidden group-hover:flex group-hover:-top-7 relative w-5  -right-6">
                <input
                  type="range"
                  min="0.5"
                  max="2"
                  step="0.25"
                  value={playbackRate}
                  onChange={(e) => setPlaybackRate(e.target.value)}
                  className="transform rotate-90 cursor-pointer w-16 h-1"
                />
              </div>
              {playbackRate}x
            </div>

            <div
              className={`hidden 2xl:block relative w-[14px] cursor-pointer group ${
                isPlaying ? "text-[#2B2B2B]" : "text-cream"
              }`}
            >
              <div className="hidden group-hover:flex group-hover:-top-7 relative w-5 -right-6">
                <input
                  className={` transform rotate-90 cursor-pointer w-16 h-1 `}
                  type="range"
                  min="0"
                  max="100"
                  value={volume}
                  onChange={handleVolumeChange}
                />
              </div>
              <div onClick={() => volumeOff()}>
                {mute && <MdVolumeOff className="w-5" />}
                {!mute && <MdVolumeUp className="w-5" />}
              </div>
            </div>
          </div>
        </div>
        <audio
          id="khoushe-1"
          ref={audioRef}
          onEnded={() => setIsPlaying(false)}
          onTimeUpdate={getCurrDuration}
          onLoadedData={(e) => {
            setDuration(e.currentTarget.duration.toFixed(2));
          }}
          src={src}
        ></audio>
      </div>
    </div>
  );
}

export default CostumPlayer;
